<template>
  <section class="w3l-about2 py-5">
    <div class="container py-lg-3">
      <div class="header-section white text-center">
        <h3>我们乐于沟通</h3>
        <p>我们重视客户的声音，客户的任何意见我们都愿意倾听，并一一回应。</p>
      </div>
      <a href="/#/contact" class="theme-button btn-primary btn">马上联系</a>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>
