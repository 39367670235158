<template>
  <div>
    <!-- about -->
    <section class="w3l-about3 py-5">
      <div class="container py-lg-3 mt-3">
        <div class="row align-items-center">
          <div class="col-md-6 book-info">
            <h3 class="mb-md-4 mb-3">定制特殊方案</h3>
            <p class="mb-md-5 mb-4">我们擅长用标准的物料来打造个性化的产品，帮助客户以较低的成本达到满意的效果。</p>
            <a href="#URL" class="btn btn-primary theme-button">了解更多</a>
          </div>
          <div class="col-md-6 mt-md-0 mt-4">
            <img src="https://o.stjan.cn/assets/images/mainslide1.jpg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
    <!-- //about -->
    <!-- about -->
    <section class="w3l-about3 py-5">
      <div class="container py-lg-3">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img src="https://o.stjan.cn/assets/images/mainslide3.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-md-6 book-info mt-md-0 mt-4">
            <h3 class="mb-md-4 mb-3">生产管理系统</h3>
            <p class="mb-md-5 mb-4">凭着专业成熟的管理系统，实现效率的精益求精，减少企业非必要成本。</p>
            <a href="#URL" class="btn btn-primary theme-button">了解更多</a>
          </div>
        </div>
      </div>
    </section>
    <!-- //about -->
  </div>
</template>

<script>
export default {
  name: "AboutSection"
}
</script>

<style scoped>

</style>
