<template>
  <!-- stats -->
  <section class="w3l-stats py-lg-5 py-4">
    <div class="gallery-inner container py-md-5 py-4">
      <div class="row stats-con text-white">
        <div
          class="col-sm-3 col-6 stats_info counter_grid"
          v-for="item in list"
          :key="item.label"
        >
          <span :class="'fa ' + item.icon"></span>
          <p class="counter">{{ item.value }}</p>
          <h4>{{ item.label }}</h4>
        </div>
      </div>
    </div>
  </section>
  <!-- //stats -->
</template>

<script>
export default {
  name: "Stats",
  data() {
    return {
      list: [
        { label: '工程项目', value: '19', icon: 'fa-bar-chart' },
        { label: '合作伙伴', value: '10', icon: 'fa-industry' },
        { label: '知识产权', value: '5', icon: 'fa-trophy' },
        { label: '忠实用户', value: '17', icon: 'fa-smile-o' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
