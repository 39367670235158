<template>
  <section class="w3l-team" id="team">
    <div class="teams1 py-5">
      <div class="container py-lg-3">
        <div class="header-section text-center">
          <h3>强大的团队</h3>
          <p class="mt-3 mb-5">我们有优秀的研发团队、运营团队和服务团队</p>
          <div class="row text-center">
            <div class="item col-lg-3 col-md-4 col-sm-6">
              <div class="d-grid team-info">
                <div class="column">
                  <a href="#url"><img src="https://o.stjan.cn/assets/images/team1.jpg" alt="" class="img-fluid team-image" /></a>
                </div>
                <div class="column">
                  <h3 class="name-pos"><a href="#url">华哥</a></h3>
                  <p>项目总监</p>
                  <div class="social">
                    <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                    <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="item col-lg-3 col-md-4 col-sm-6">
              <div class="d-grid team-info">
                <div class="column">
                  <a href="#url"><img src="https://o.stjan.cn/assets/images/team2.jpg" alt="" class="img-fluid team-image" /></a>
                </div>
                <div class="column">
                  <h3 class="name-pos"><a href="#url">胡子</a></h3>
                  <p>首席程序员</p>
                  <div class="social">
                    <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                    <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="item col-lg-3 col-md-4 col-sm-6">
              <div class="d-grid team-info">
                <div class="column">
                  <a href="#url"><img src="https://o.stjan.cn/assets/images/team3.jpg" alt="" class="img-fluid team-image" /></a>
                </div>
                <div class="column">
                  <h3 class="name-pos"><a href="#url">小冯</a></h3>
                  <p>硬件工程师</p>
                  <div class="social">
                    <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                    <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="item col-lg-3 col-md-4 col-sm-6">
              <div class="d-grid team-info">
                <div class="column">
                  <a href="#url"><img src="https://o.stjan.cn/assets/images/team4.jpg" alt="" class="img-fluid team-image" /></a>
                </div>
                <div class="column">
                  <h3 class="name-pos"><a href="#url">小余</a></h3>
                  <p>售后工程师</p>
                  <div class="social">
                    <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                    <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Teams"
}
</script>

<style scoped>

</style>
