<template>
  <section class="w3l-about1" id="about">
    <div class="header-section text-center">
      <h2>关于我们</h2>
      <p class="mt-3 mb-0">本公司主要经营：物联网终端设备销售，老设备智能化升级解决方案，生产管理系统与机器的对接解决方案，第三方传感器与原系统深度对接解决方案。
      </p>
    </div>
    <div class="new-block py-5">
      <div class="container py-lg-5 py-md-4">
        <div class="row middle-section">
          <div class="col-lg-6 section-width align-self">
            <h5><span>{{ experienceYear }}</span> 年行业经验</h5>
            <h2>我们相信合作共赢</h2>
            <p class="mt-4 pb-3">本公司理念“诚信为本、客户至上、质量优先、互利双赢”。本公司致力于解决企业转型的痛点，推动企业的升级改造，为2025中国制造出一份力。本公司自创立以来，为客户的设备升级改造提供解决方案，加快了企业内部的信息流通，减少了人力成本，提高了生产效率，得到了广大客户的认可。欢迎客户随时来电咨询、洽谈合作，我们将竭诚为您服务。</p>
            <a href="/#/about" class="btn btn-primary theme-button mt-4"> 了解更多</a>
          </div>
          <div class="col-lg-6 history-info mt-lg-0 mt-5 pt-lg-0 pt-md-4">
            <div class="position-relative img-border">
              <img src="https://o.stjan.cn/assets/images/about.jpg" class="img-fluid video-popup-image" alt="about-image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderSection",
  computed: {
    experienceYear() {
      return new Date().getFullYear() - 1996
    }
  }
}
</script>

<style scoped>

</style>
