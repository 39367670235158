<template>
  <div>
    <header-section />
    <stats />
    <teams />
    <contact />
    <about-section />
  </div>
</template>

<script>
import {
  HeaderSection,
  Stats,
  Teams,
  Contact,
  AboutSection
} from './components'

export default {
  name: 'About',
  components: {
    HeaderSection,
    Stats,
    Teams,
    Contact,
    AboutSection
  },
  data() {
    return {
      title: '关于 - 珠海市星舰科技有限公司'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      document.title = this.title
    }
  }
}
</script>

<style scoped>

</style>
